import React from "react";
import zethaLogo from "../../assets/zethalogo.webp";

const Loader = () => {
  return (
    <div className="flex items-center justify-center">
      <div className="relative flex items-center justify-center">
        <img src={zethaLogo} alt="Zetha Logo" className="h-10 w-10 z-10" />
        <div
          className="absolute h-20 w-20 rounded-full border-4 border-transparent animate-spin"
          style={{
            borderTopColor: "#B19CD9", // Light purple
            borderRightColor: "#9A7DCD", // Medium-light purple
            borderBottomColor: "#7B5DC9", // Medium-dark purple
            borderLeftColor: "#6930C3", // Dark purple
          }}
        ></div>
      </div>
    </div>
  );
};

export default Loader;
