import React, { useEffect, useState } from "react";
import Lenis from "@studio-freight/lenis";
import { NavLink } from "react-router-dom";
import AnimatedSection from "../Components/Animation/AnimatedSection";
import logo from "./logo.webp";
import "../Home/Home.css";

const Navbar = () => {
  const [showSecondDiv, setShowSecondDiv] = useState(false);

  useEffect(() => {
    const lenis = new Lenis({
      duration: 1.2,
      easing: (t) => Math.min(1, 1.001 - Math.pow(2, -10 * t)),
      smooth: true,
    });

    function raf(time) {
      lenis.raf(time);
      requestAnimationFrame(raf);
    }

    requestAnimationFrame(raf);

    return () => {
      lenis.destroy();
    };
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowSecondDiv(true);
    }, 1000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <AnimatedSection className="flex border-gray-300 border-b fixed flex-col z-50 md:flex-row items-center justify-between w-full font-inter font-semibold bg-white shadow-md transition-all duration-300">
      <div className="flex items-center justify-between w-full md:w-40 border-gray-300 border-b p-4 md:border-none">
        <NavLink to="/">
          <img src={logo} alt="zetha_logo" className="h-10 w-full" />
        </NavLink>
        <div className="flex items-center justify-center gap-x-1 md:hidden">
          <p className="p-5 text-xs font-normal">Get Started</p>
          <NavLink
            to="/contact-us"
            className="px-3 py-2 text-xs bg-main font-normal rounded-md text-white hover:bg-purple-700"
          >
            Book a Demo
          </NavLink>
        </div>
      </div>
      <div
        className={`flex flex-row md:pr-4 py-6 md:pb-0 md:py-0 gap-x-5 md:gap-x-4 lg:gap-x-16 justify-center items-center w-full md:w-auto order-2 md:order-1 md:absolute md:left-1/2 md:transform md:-translate-x-1/2 transition-opacity duration-500 ${
          showSecondDiv ? "opacity-100" : "opacity-0"
        }`}
      >
        <NavLink
          to="/"
          className={({ isActive }) =>
            `text-xs hover:text-main ${isActive ? "text-main font-bold" : ""}`
          }
        >
          Home
        </NavLink>
        <NavLink
          to="/insight"
          className={({ isActive }) =>
            `text-xs hover:text-main ${isActive ? "text-main font-bold" : ""}`
          }
        >
          Insights
        </NavLink>
        <NavLink
          to="/choose-us"
          className={({ isActive }) =>
            `text-xs hover:text-main ${isActive ? "text-main font-bold" : ""}`
          }
        >
          Why Us
        </NavLink>
        <NavLink
          to="/about-us"
          className={({ isActive }) =>
            `text-xs hover:text-main ${isActive ? "text-main font-bold" : ""}`
          }
        >
          About
        </NavLink>
        <NavLink
          to="/contact-us"
          className={({ isActive }) =>
            `text-xs hover:text-main ${isActive ? "text-main font-bold" : ""}`
          }
        >
          Contact
        </NavLink>
      </div>
      <div className="hidden md:flex items-center justify-center gap-x-4 pr-5">
        <p className="text-xs font-normal">Get Started</p>
        <NavLink
          to="/contact-us"
          className="px-3 py-2 text-xs bg-main rounded-md font-normal text-white hover:bg-purple-700"
        >
          Book a Demo
        </NavLink>
      </div>
    </AnimatedSection>
  );
};

export default Navbar;
