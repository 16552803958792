import React, { useEffect } from "react";
import Lenis from "@studio-freight/lenis";
import { NavLink } from "react-router-dom";

import ZethaFooterLogo from "./logoFooter.webp";
import { x, linkedin, whatsapp } from "./Assets";
import AnimatedSection from "../Components/Animation/AnimatedSection";

const Footer = () => {
  useEffect(() => {
    const lenis = new Lenis({
      duration: 1.2,
      easing: (t) => Math.min(1, 1.001 - Math.pow(2, -10 * t)),
      smooth: true,
    });

    function raf(time) {
      lenis.raf(time);
      requestAnimationFrame(raf);
    }

    requestAnimationFrame(raf);

    return () => {
      lenis.destroy();
    };
  }, []);
  return (
    <div className="bg-black font-inter p-5 px-10 flex w-full flex-col items-center justify-center">
      <AnimatedSection className="flex items-center justify-center flex-col md:flex-row w-full md:justify-between pt-10">
        <img
          src={ZethaFooterLogo}
          alt="Zetha Footer Logo"
          className="w-32 h-12"
        />
        <div className="flex flex-col items-center justify-center md:flex-row pt-10 md:pt-0 gap-y-3">
          <NavLink
            to="/"
            className={({ isActive }) =>
              `text-gray-400 text-sm md:ml-5 md:mr-5 hover:text-gray-300 ${
                isActive ? "text-main" : ""
              }`
            }
          >
            Home
          </NavLink>
          <NavLink
            to="/insight"
            className={({ isActive }) =>
              `text-gray-400 text-sm md:ml-5 md:mr-5 hover:text-gray-300 ${
                isActive ? "text-main" : ""
              }`
            }
          >
            Insights
          </NavLink>
          <NavLink
            to="/choose-us"
            className={({ isActive }) =>
              `text-gray-400 text-sm md:ml-5 md:mr-5 hover:text-gray-300 ${
                isActive ? "text-main" : ""
              }`
            }
          >
            Why Us
          </NavLink>
          <NavLink
            to="/about-us"
            className={({ isActive }) =>
              `text-gray-400 text-sm md:ml-5 md:mr-5 hover:text-gray-300 ${
                isActive ? "text-main" : ""
              }`
            }
          >
            About
          </NavLink>
          <NavLink
            to="/contact-us"
            className={({ isActive }) =>
              `text-gray-400 text-sm md:ml-5 md:mr-5 hover:text-gray-300 ${
                isActive ? "text-main" : ""
              }`
            }
          >
            Contact
          </NavLink>
        </div>
      </AnimatedSection>
      <hr className="bg-gray-900 w-full mt-5" />
      <AnimatedSection className="flex items-center pb-5 justify-center flex-col-reverse w-full md:flex-row md:justify-between mt-5">
        <p className="pt-5 md:pt-0 flex items-center justify-center flex-col">
          <span className="text-gray-400 text-xs md:text-sm">
            Zetha Analytics © 2024 | All Rights Reserved.
          </span>
          <a href="https://ansaribrahim.me/">
            <span className="text-gray-400 text-xs md:text-sm hover:text-main">
              Crafted by AnsarIbrahim
            </span>
          </a>
        </p>
        <div className="flex items-center justify-center gap-x-2">
          <a
            href="https://x.com/zethaanalytics"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={x} alt="X (formerly Twitter)" className="w-10 h-10" />
          </a>
          <a
            href="https://www.linkedin.com/in/zethaanalytics/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={linkedin} alt="LinkedIn" className="w-10 h-10" />
          </a>
          <a
            href="https://wa.me/9597795433"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={whatsapp} alt="WhatsApp" className="w-10 h-10" />
          </a>
        </div>
      </AnimatedSection>
    </div>
  );
};

export default Footer;
